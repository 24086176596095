<!-- src/views/Impressum.vue -->
<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <h1>Impressum</h1>
                <p><strong>Soccer-Ratings</strong></p>
                <p>Musterstraße 123<br>
                    12345 Musterstadt<br>
                    Deutschland</p>
                <p>Kontakt: info@soccer-ratings.de</p>
                <!-- Weitere Impressumsinformationen hier -->
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "ImpressumView",
}
</script>

<style scoped>
/* Eigene Styles für die Impressumsseite */
</style>