import { createRouter, createWebHistory } from "vue-router";
import MainLayout from "@/layouts/MainLayout.vue";
import DashboardHome from "@/views/DashboardHome.vue";
import ImpressumView from "@/views/Impressum.vue";
import BetBuddy from "@/views/BetBuddy.vue";
import LeagueStats from "@/views/LeagueStats.vue";

const routes = [
  {
    path: "/",
    component: MainLayout,
    children: [
      {
        path: "",
        name: "DashboardHome",
        component: DashboardHome,
      },
      // Weitere Routen können später ergänzt werden
      {
        path: "impressum",
        name: "Impressum",
        component: ImpressumView,
      },
      {
        path: "betbuddy",
        name: "BetBuddy",
        component: BetBuddy,
      },
      {
        path: "league-stats",
        name: "LeagueStats",
        component: LeagueStats,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
