<!-- eslint-disable vue/valid-v-slot -->
<template>
    <v-container fluid class="px-4">
        <!-- Navigation: Datumsauswahl -->
        <v-row align="center" class="mb-4">
            <v-col cols="12" md="4" class="text-center">
                <v-btn icon @click="prevDay">
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <span class="mx-2">{{ formattedDate }}</span>
                <v-btn icon @click="nextDay">
                    <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <!-- Desktop-Version: v-table -->
        <div v-if="matches.length > 0" class="d-none d-md-block">
            <v-table height="auto" fixed-header>
                <thead>
                    <tr>
                        <th>Anpfiff</th>
                        <th>Liga</th>
                        <th>Heimteam</th>
                        <th>Ergebnis</th>
                        <th>Auswärtsteam</th>
                        <th>Homewin</th>
                        <th>Awaywin</th>
                        <th>O2.5</th>
                        <th>BTTS</th>
                        <th>Tor in beiden HZ</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="match in matches" :key="match.match.id" style="cursor: pointer"
                        @click="goToMatch(match.match.id)">
                        <td>{{ match.match && match.match.formatted_time ? match.match.formatted_time : '-' }}</td>
                        <td>{{ match.match && match.match.league ? match.match.league : '-' }}</td>
                        <td>{{ match.match && match.match.home_team ? match.match.home_team : '-' }}</td>
                        <td>
                            <span
                                v-if="match.match && match.match.home_goal_count !== null && match.match.away_goal_count !== null">
                                {{ match.match.home_goal_count }} : {{ match.match.away_goal_count }}
                            </span>
                            <span v-else>-</span>
                        </td>
                        <td>{{ match.match && match.match.away_team ? match.match.away_team : '-' }}</td>
                        <!-- Boolean-Spalten: Icons -->
                        <td>
                            <v-icon color="green" v-if="match.homewin">mdi-check</v-icon>
                            <v-icon color="red" v-else>mdi-close</v-icon>
                        </td>
                        <td>
                            <v-icon color="green" v-if="match.awaywin">mdi-check</v-icon>
                            <v-icon color="red" v-else>mdi-close</v-icon>
                        </td>
                        <td>
                            <v-icon color="green" v-if="match.over_2_5">mdi-check</v-icon>
                            <v-icon color="red" v-else>mdi-close</v-icon>
                        </td>
                        <td>
                            <v-icon color="green" v-if="match.btts_yes">mdi-check</v-icon>
                            <v-icon color="red" v-else>mdi-close</v-icon>
                        </td>
                        <td>
                            <v-icon color="green" v-if="match.goals_in_each_half">mdi-check</v-icon>
                            <v-icon color="red" v-else>mdi-close</v-icon>
                        </td>
                    </tr>
                </tbody>
            </v-table>
        </div>

        <!-- Mobile-Version: Cards -->
        <div v-if="matches.length > 0" class="d-block d-md-none">
            <v-card v-for="match in matches" :key="match.match.id" class="mb-3" style="cursor: pointer"
                @click="goToMatch(match.match.id)">
                <v-card-title>
                    {{ match.match && match.match.home_team ? match.match.home_team : '-' }} vs
                    {{ match.match && match.match.away_team ? match.match.away_team : '-' }}
                </v-card-title>
                <v-card-subtitle>{{ match.match && match.match.league ? match.match.league : '-' }}</v-card-subtitle>
                <v-card-text>
                    <div><strong>Anpfiff:</strong> {{ match.match && match.match.formatted_time ?
                        match.match.formatted_time : '-' }}</div>
                    <div>
                        <strong>Ergebnis:</strong>
                        {{ match.match && match.match.home_goal_count !== null && match.match.away_goal_count !== null
                            ? match.match.home_goal_count + ' : ' + match.match.away_goal_count
                            : '-' }}
                    </div>
                    <!-- Boolean-Spalten als Icons in der mobilen Ansicht -->
                    <div class="d-flex flex-column gap-2 mt-2">
                        <div>
                            <strong>Homewin:</strong>
                            <v-icon color="green" v-if="match.homewin">mdi-check</v-icon>
                            <v-icon color="red" v-else>mdi-close</v-icon>
                        </div>
                        <div>
                            <strong>Awaywin:</strong>
                            <v-icon color="green" v-if="match.awaywin">mdi-check</v-icon>
                            <v-icon color="red" v-else>mdi-close</v-icon>
                        </div>
                        <div>
                            <strong>O2.5:</strong>
                            <v-icon color="green" v-if="match.over_2_5">mdi-check</v-icon>
                            <v-icon color="red" v-else>mdi-close</v-icon>
                        </div>
                        <div>
                            <strong>BTTS:</strong>
                            <v-icon color="green" v-if="match.btts_yes">mdi-check</v-icon>
                            <v-icon color="red" v-else>mdi-close</v-icon>
                        </div>
                        <div>
                            <strong>Tor in beiden Halbzeiten:</strong>
                            <v-icon color="green" v-if="match.goals_in_each_half">mdi-check</v-icon>
                            <v-icon color="red" v-else>mdi-close</v-icon>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </div>

        <!-- Hinweis, wenn keine Matches vorhanden sind -->
        <div v-else class="text-center my-4">
            <v-alert type="info" color="info">
                Für diesen Tag sind keine Spiele verfügbar.
            </v-alert>
        </div>
    </v-container>
</template>

<script>
import moment from 'moment'
import axios from 'axios'

export default {
    name: 'BetBuddy',
    data() {
        return {
            currentDate: new Date(),
            matches: [],
            headers: [
                { title: 'Anpfiff', key: 'formatted_time' },
                { title: 'Liga', key: 'league' },
                { title: 'Heimteam', key: 'home_team' },
                { title: 'Ergebnis', key: 'result' },
                { title: 'Auswärtsteam', key: 'away_team' },
                { title: 'Homewin', key: 'homewin' },
                { title: 'Awaywin', key: 'awaywin' },
                { title: 'O2.5', key: 'over_2_5' },
                { title: 'BTTS', key: 'btts_yes' },
                { title: 'Tor in beiden HZ', key: 'goals_in_each_half' },
            ],
        }
    },
    computed: {
        formattedDate() {
            return moment(this.currentDate).format('DD.MM.YYYY')
        },
    },
    methods: {
        fetchMatches() {
            const formattedDate = moment(this.currentDate).format('YYYY-MM-DD')
            axios.get(`${process.env.VUE_APP_API_URL}/betbuddy/?date=${formattedDate}`)
                .then(response => {
                    this.matches = response.data
                })
                .catch(error => {
                    console.error("Fehler beim Laden der BetBuddy-Daten:", error)
                })
        },
        prevDay() {
            this.currentDate = new Date(this.currentDate.getTime() - 86400000)
            this.fetchMatches()
        },
        nextDay() {
            this.currentDate = new Date(this.currentDate.getTime() + 86400000)
            this.fetchMatches()
        },
        goToMatch(id) {
            this.$router.push(`/matches/${id}`)
        },
        formatPercentage(value) {
            return value != null ? (value * 100).toFixed(1) + '%' : '-'
        }
    },
    mounted() {
        this.fetchMatches()
    }
}
</script>

<style scoped>
/* Hier kannst du zusätzliche Styles zur Optimierung hinzufügen */
</style>