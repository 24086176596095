<template>
    <v-container fluid>
        <h2 class="px-4">Matches</h2>
        <MatchesTable />
    </v-container>
</template>

<script>
import MatchesTable from '@/components/MatchesTable.vue'

export default {
    name: 'DashboardHome',
    components: {
        MatchesTable,
    },
}
</script>