<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-container fluid class="px-4">
    <!-- Navigation: kalenderwoche und Option Toggle -->
    <v-row class="mb-4">
      <!-- Kalenderwoche-Navigation -->
      <v-col cols="12" md="4">
        <v-btn icon @click="prevWeek">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <span class="mx-2">{{ formattedWeek }}</span>
        <v-btn icon @click="nextWeek" v-if="!isCurrentWeek">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>

      <!-- Umschalter für Wettart -->
      <v-col cols="12" md="4" class="text-center">
        <v-btn-toggle v-model="selectedOption" dense>
          <v-btn value="O2.5">O2.5</v-btn>
          <v-btn value="BTTS">BTTS</v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <!-- Desktop-Version: v-table -->
    <div v-if="sortedLeagues.length > 0" class="d-none d-md-block">
      <v-table height="auto" fixed-header>
        <thead>
          <tr>
            <th>#</th>
            <th>Trend</th>
            <th>League</th>
            <th>gespielte Spiele</th>
            <th @click="sortBy(percentageKey)" style="cursor: pointer;">
              <span v-if="selectedOption === 'O2.5'">O2.5 (%)</span>
              <span v-else>BTTS (%)</span>
              <v-icon small v-if="sortColumn === percentageKey">
                {{ sortDesc ? 'mdi-arrow-down' : 'mdi-arrow-up' }}
              </v-icon>
            </th>
            
            <th @click="sortBy('goals_per_match')" style="cursor: pointer;">
              Goals/Match
              <v-icon small v-if="sortColumn === 'goals_per_match'">
                {{ sortDesc ? 'mdi-arrow-down' : 'mdi-arrow-up' }}
              </v-icon>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(stat, index) in sortedLeagues" :key="stat.id" style="cursor: pointer" @click="goToLeague(stat.id)">
            <!-- Platzierung -->
            <td>{{ index + 1 }}.</td>
            <!-- Trend -->
            <td>
              <template v-if="selectedOption === 'O2.5'">
                <span v-if="stat.trend_over_2_5 === 'up'">
                  <v-icon color="green">mdi-arrow-up-bold</v-icon>
                </span>
                <span v-else-if="stat.trend_over_2_5 === 'down'">
                  <v-icon color="red">mdi-arrow-down-bold</v-icon>
                </span>
                <span v-else-if="stat.trend_over_2_5 === 'same'">
                  <v-icon color="orange">mdi-minus</v-icon>
                </span>
                <span v-else-if="stat.trend_over_2_5 === 'new'">
                  <v-icon color="blue">mdi-new-box</v-icon>
                </span>
                <span v-else>
                  {{ stat.trend_over_2_5 }}
                </span>
              </template>
              <template v-else>
                <span v-if="stat.trend_btts === 'up'">
                  <v-icon color="green">mdi-arrow-up-bold</v-icon>
                </span>
                <span v-else-if="stat.trend_btts === 'down'">
                  <v-icon color="red">mdi-arrow-down-bold</v-icon>
                </span>
                <span v-else-if="stat.trend_btts === 'same'">
                  <v-icon color="orange">mdi-minus</v-icon>
                </span>
                <span v-else-if="stat.trend_btts === 'new'">
                  <v-icon color="blue">mdi-new-box</v-icon>
                </span>
                <span v-else>
                  {{ stat.trend_btts }}
                </span>
              </template>
            </td>
            <!-- League -->
            <td>{{ stat.league }}</td>
            <!-- Number of Matches -->
            <td>{{ stat.number_of_matches }}</td>
             <!-- Percent -->
            <td>
              <template v-if="selectedOption === 'O2.5'">
                {{ formatPercentage(stat.percent_over_2_5) }}
              </template>
              <template v-else>
                {{ formatPercentage(stat.percent_btts) }}
              </template>
            </td>
            <!-- Goals per Match -->
            <td>{{ stat.goals_per_match }}</td>
          </tr>
        </tbody>
      </v-table>
    </div>

    <!-- Mobile-Version: Cards -->
    <div v-if="sortedLeagues.length > 0" class="d-block d-md-none">
      <v-card v-for="(stat, index) in sortedLeagues" :key="stat.id" class="mb-3" style="cursor: pointer"
        @click="goToLeague(stat.id)">
        <v-card-title>{{ stat.league }}</v-card-title>
        <v-card-subtitle>{{ stat.date }}</v-card-subtitle>
        <v-card-text>
          <div><strong>Platzierung:</strong> {{ index + 1 }}</div>
          <div>
            <strong>Trend:</strong>
            <template v-if="selectedOption === 'O2.5'">
              <span v-if="stat.trend_over_2_5 === 'up'">
                <v-icon color="green">mdi-arrow-up-bold</v-icon>
              </span>
              <span v-else-if="stat.trend_over_2_5 === 'down'">
                <v-icon color="red">mdi-arrow-down-bold</v-icon>
              </span>
              <span v-else-if="stat.trend_over_2_5 === 'same'">
                <v-icon color="orange">mdi-minus</v-icon>
              </span>
              <span v-else-if="stat.trend_over_2_5 === 'new'">
                <v-icon color="blue">mdi-new-box</v-icon>
              </span>
              <span v-else>
                {{ stat.trend_over_2_5 }}
              </span>
            </template>
            <template v-else>
              <span v-if="stat.trend_btts === 'up'">
                <v-icon color="green">mdi-arrow-up-bold</v-icon>
              </span>
              <span v-else-if="stat.trend_btts === 'down'">
                <v-icon color="red">mdi-arrow-down-bold</v-icon>
              </span>
              <span v-else-if="stat.trend_btts === 'same'">
                <v-icon color="orange">mdi-minus</v-icon>
              </span>
              <span v-else-if="stat.trend_btts === 'new'">
                <v-icon color="blue">mdi-new-box</v-icon>
              </span>
              <span v-else>
                {{ stat.trend_btts }}
              </span>
            </template>
          </div>
          <div><strong>Spiele:</strong> {{ stat.number_of_matches }}</div>
          <div>
            <strong>
              <template v-if="selectedOption === 'O2.5'">O2.5:</template>
              <template v-else>BTTS:</template>
            </strong>
            {{ selectedOption === 'O2.5'
              ? formatPercentage(stat.percent_over_2_5)
              : formatPercentage(stat.percent_btts) }}
          </div>
          <div><strong>Tore/Match:</strong> {{ stat.goals_per_match }}</div>
        </v-card-text>
      </v-card>
    </div>

    <!-- Hinweis, wenn keine Daten vorhanden -->
    <div v-else class="text-center my-4">
      <v-alert type="info" color="info">
        Für diesen Tag sind keine Statistiken verfügbar.
      </v-alert>
    </div>
  </v-container>
</template>

<script>
import moment from 'moment'
import axios from 'axios'


export default {
  name: 'LeagueStats',
  data() {
    return {
      selectedDate: new Date(), // Basisdatum, von dem die Kalenderwoche abgeleitet wird
      selectedOption: 'O2.5', // Alternativ: "BTTS"
      leagueStats: [],
      error: null,
      loading: false,
      sortColumn: '',
      sortDesc: true,
    }
  },
  computed: {
    formattedWeek() {
      const m = moment(this.selectedDate);
      const week = m.isoWeek();
      const year = m.year();
      const start = m.clone().startOf('isoWeek').format('DD.MM.YYYY');
      const end = m.clone().endOf('isoWeek').format('DD.MM.YYYY');
      return `KW${week} ${year} (${start} - ${end})`;
    },
    isCurrentWeek() {
      const now = moment();
      return (
        moment(this.selectedDate).isoWeek() === now.isoWeek() &&
        moment(this.selectedDate).year() === now.year()
      );
    },
    // Dynamisch den Schlüssel für die Prozent-Spalte abhängig von der ausgewählten Option
    percentageKey() {
      return this.selectedOption === 'O2.5' ? 'percent_over_2_5' : 'percent_btts'
    },
    sortedLeagues() {
      let stats = [...this.leagueStats]
      if (this.sortColumn) {
        stats.sort((a, b) => {
          let valA = a[this.sortColumn]
          let valB = b[this.sortColumn]
          if (valA == null) valA = 0
          if (valB == null) valB = 0
          if (typeof valA === 'string') {
            valA = valA.toLowerCase()
            valB = valB.toLowerCase()
          }
          if (valA < valB) return this.sortDesc ? 1 : -1
          if (valA > valB) return this.sortDesc ? -1 : 1
          return 0
        })
      } else {
        // Standard-Sortierung: Nach Prozent-Spalte absteigend
        stats.sort((a, b) => b[this.percentageKey] - a[this.percentageKey])
      }
      return stats
    },
  },
  methods: {
    fetchLeagueStats() {
      this.loading = true;
      const week = moment(this.selectedDate).isoWeek();
      const year = moment(this.selectedDate).year();
      // console.log("Lade Statistiken für KW", week, year);
      axios.get(`${process.env.VUE_APP_API_URL}/league-stats/?week=${week}&year=${year}`)
        .then(response => {
          this.leagueStats = response.data;
          this.loading = false;
          // console.log("Statistiken geladen:", this.leagueStats);
        })
        .catch(error => {
          this.error = "Fehler beim Laden der Statistiken.";
          console.error("Fehler beim Laden der Statistiken:", error);
          this.loading = false;
        })
    },
    prevWeek() {
      // Subtrahiere 7 Tage vom Basisdatum
      this.selectedDate = new Date(this.selectedDate.getTime() - 7 * 86400000)
      this.fetchLeagueStats()
    },
    nextWeek() {
      // Addiere 7 Tage zum Basisdatum
      this.selectedDate = new Date(this.selectedDate.getTime() + 7 * 86400000)
      this.fetchLeagueStats()
    },
    goToLeague(id) {
      this.$router.push(`/leagues/${id}`)
    },
    formatPercentage(value) {
      return value != null ? (value * 100).toFixed(1) + '%' : '-'
    },
    sortBy(column) {
      if (this.sortColumn === column) {
        this.sortDesc = !this.sortDesc;
      } else {
        this.sortColumn = column;
        this.sortDesc = true;
      }
    },
  },
  mounted() {
    this.fetchLeagueStats()
  }
}
</script>

<style scoped>
/* Hier können weitere Styles angepasst werden */
</style>