<!-- eslint-disable vue/valid-v-slot -->
<template>
    <v-container fluid class="px-4">
        <!-- Navigation: Datum und Wettart-Umschalter -->
        <v-row align="center" class="mb-4">
            <!-- Datum-Navigation -->
            <v-col cols="12" md="4" class="text-center">
                <v-btn icon @click="prevDay">
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <span class="mx-2">{{ formattedDate }}</span>
                <v-btn icon @click="nextDay">
                    <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
            </v-col>

            <!-- Umschalter für Wettart -->
            <v-col cols="12" md="4" class="text-center">
                <v-btn-toggle v-model="selectedProbability" dense>
                    <v-btn value="1X2">1X2</v-btn>
                    <v-btn value="O_15">O1.5</v-btn>
                    <v-btn value="O_25">O2.5</v-btn>
                    <v-btn value="BTTS">BTTS</v-btn>
                </v-btn-toggle>
            </v-col>
        </v-row>

        <!-- Desktop-Version: v-table -->
        <div v-if="matches.length > 0" class="d-none d-md-block">
            <v-table height="auto" fixed-header>
                <thead>
                    <tr>
                        <th>Anpfiff</th>
                        <th>Liga</th>
                        <th>Heimteam</th>
                        <th>Ergebnis</th>
                        <th>Auswärtsteam</th>
                        <th>Wahrscheinlichkeiten</th>
                        <th>Quoten</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="match in matches" :key="match.id" style="cursor: pointer" @click="goToMatch(match.id)">
                        <td>{{ match.formatted_time }}</td>
                        <td>{{ match.league }}</td>
                        <td>{{ match.home_team }}</td>
                        <td>
                            {{ match.home_goal_count == null || match.away_goal_count == null
                                ? '-'
                                : match.home_goal_count + ' : ' + match.away_goal_count }}
                        </td>
                        <td>{{ match.away_team }}</td>
                        <td>
                            <template v-if="match.prediction">
                                <template v-if="selectedProbability === '1X2'">
                                    <div class="stacked-progress">
                                        <div class="segment segment-home"
                                            :style="{ width: (match.prediction.proba_home_win * 100) + '%' }"
                                            :title="'1: ' + formatPercentage(match.prediction.proba_home_win)">
                                            <span class="segment-label">{{
                                                formatPercentage(match.prediction.proba_home_win) }}</span>
                                        </div>
                                        <div class="segment segment-draw"
                                            :style="{ width: (match.prediction.proba_draw * 100) + '%' }"
                                            :title="'Draw: ' + formatPercentage(match.prediction.proba_draw)">
                                            <span class="segment-label">{{ formatPercentage(match.prediction.proba_draw)
                                            }}</span>
                                        </div>
                                        <div class="segment segment-away"
                                            :style="{ width: (match.prediction.proba_away_win * 100) + '%' }"
                                            :title="'2: ' + formatPercentage(match.prediction.proba_away_win)">
                                            <span class="segment-label">{{
                                                formatPercentage(match.prediction.proba_away_win) }}</span>
                                        </div>
                                    </div>
                                </template>
                                <template v-else-if="selectedProbability === 'O_15'">
                                    <div class="stacked-progress">
                                        <div class="segment segment-over"
                                            :style="{ width: (match.prediction.proba_over_1_5 * 100) + '%' }"
                                            :title="'Over: ' + formatPercentage(match.prediction.proba_over_1_5)">
                                            <span class="segment-label">{{
                                                formatPercentage(match.prediction.proba_over_1_5) }}</span>
                                        </div>
                                        <div class="segment segment-under"
                                            :style="{ width: (match.prediction.proba_under_1_5 * 100) + '%' }"
                                            :title="'Under: ' + formatPercentage(match.prediction.proba_under_1_5)">
                                            <span class="segment-label">{{
                                                formatPercentage(match.prediction.proba_under_1_5) }}</span>
                                        </div>
                                    </div>
                                </template>
                                <template v-else-if="selectedProbability === 'O_25'">
                                    <div class="stacked-progress">
                                        <div class="segment segment-over"
                                            :style="{ width: (match.prediction.proba_over_2_5 * 100) + '%' }"
                                            :title="'Over: ' + formatPercentage(match.prediction.proba_over_2_5)">
                                            <span class="segment-label">{{
                                                formatPercentage(match.prediction.proba_over_2_5) }}</span>
                                        </div>
                                        <div class="segment segment-under"
                                            :style="{ width: (match.prediction.proba_under_2_5 * 100) + '%' }"
                                            :title="'Under: ' + formatPercentage(match.prediction.proba_under_2_5)">
                                            <span class="segment-label">{{
                                                formatPercentage(match.prediction.proba_under_2_5) }}</span>
                                        </div>
                                    </div>
                                </template>
                                <template v-else-if="selectedProbability === 'BTTS'">
                                    <div class="stacked-progress">
                                        <div class="segment segment-btts-yes"
                                            :style="{ width: (match.prediction.proba_btts_yes * 100) + '%' }"
                                            :title="'Yes: ' + formatPercentage(match.prediction.proba_btts_yes)">
                                            <span class="segment-label">{{
                                                formatPercentage(match.prediction.proba_btts_yes) }}</span>
                                        </div>
                                        <div class="segment segment-btts-no"
                                            :style="{ width: (match.prediction.proba_btts_no * 100) + '%' }"
                                            :title="'No: ' + formatPercentage(match.prediction.proba_btts_no)">
                                            <span class="segment-label">{{
                                                formatPercentage(match.prediction.proba_btts_no) }}</span>
                                        </div>
                                    </div>
                                </template>
                            </template>
                            <template v-else>
                                Keine Vorhersage
                            </template>
                        </td>
                        <!-- Neue Spalte: Quoten -->
                        <td>
                            <template v-if="match.odds">
                                <template v-if="selectedProbability === '1X2'">
                                    <div class="d-flex justify-space-between">
                                        <span>1: {{ formatOdds(match.odds.odds_ft_1) }}</span>
                                        <span>X: {{ formatOdds(match.odds.odds_ft_x) }}</span>
                                        <span>2: {{ formatOdds(match.odds.odds_ft_2) }}</span>
                                    </div>
                                </template>
                                <template v-else-if="selectedProbability === 'O_15'">
                                    <div class="d-flex justify-space-between">
                                        <span>Over: {{ formatOdds(match.odds.odds_ft_over15) }}</span>
                                        <span>Under: {{ formatOdds(match.odds.odds_ft_under15) }}</span>
                                    </div>
                                </template>
                                <template v-else-if="selectedProbability === 'O_25'">
                                    <div class="d-flex justify-space-between">
                                        <span>Over: {{ formatOdds(match.odds.odds_ft_over25) }}</span>
                                        <span>Under: {{ formatOdds(match.odds.odds_ft_under25) }}</span>
                                    </div>
                                </template>
                                <template v-else-if="selectedProbability === 'BTTS'">
                                    <div class="d-flex justify-space-between">
                                        <span>Yes: {{ formatOdds(match.odds.odds_btts_yes) }}</span>
                                        <span>No: {{ formatOdds(match.odds.odds_btts_no) }}</span>
                                    </div>
                                </template>
                            </template>
                            <template v-else>
                                -
                            </template>
                        </td>
                    </tr>
                </tbody>
            </v-table>
        </div>
        <!-- Hinweis, wenn keine Matches vorhanden sind -->
        <div v-else class="text-center my-4 d-none d-md-block">
            <v-alert type="info" color="info">
                Für diesen Tag sind keine Spiele verfügbar.
            </v-alert>
        </div>

        <!-- Mobile-Version: Cards -->
        <div v-if="matches.length > 0" class="d-block d-md-none">
            <v-card v-for="match in matches" :key="match.id" class="mb-3" style="cursor: pointer"
                @click="goToMatch(match.id)">
                <v-card-title>
                    {{ match.home_team }} vs {{ match.away_team }}
                </v-card-title>
                <v-card-subtitle>{{ match.league }}</v-card-subtitle>
                <v-card-text>
                    <div><strong>Anpfiff:</strong> {{ match.formatted_time }}</div>
                    <div>
                        <strong>Ergebnis:</strong>
                        {{ match.home_goal_count == null || match.away_goal_count == null
                            ? '-'
                            : match.home_goal_count + ' : ' + match.away_goal_count }}
                    </div>
                    <div class="mb-2">
                        <strong>Wahrscheinlichkeiten:</strong>
                    </div>
                    <div v-if="match.prediction">
                        <!-- Gestapelte Progress Bar -->
                        <template v-if="selectedProbability === '1X2'">
                            <div class="stacked-progress mb-1">
                                <div class="segment segment-home"
                                    :style="{ width: (match.prediction.proba_home_win * 100) + '%' }"
                                    :title="'1: ' + formatPercentage(match.prediction.proba_home_win)">
                                    <span class="segment-label">{{ formatPercentage(match.prediction.proba_home_win)
                                        }}</span>
                                </div>
                                <div class="segment segment-draw"
                                    :style="{ width: (match.prediction.proba_draw * 100) + '%' }"
                                    :title="'Draw: ' + formatPercentage(match.prediction.proba_draw)">
                                    <span class="segment-label">{{ formatPercentage(match.prediction.proba_draw)
                                        }}</span>
                                </div>
                                <div class="segment segment-away"
                                    :style="{ width: (match.prediction.proba_away_win * 100) + '%' }"
                                    :title="'2: ' + formatPercentage(match.prediction.proba_away_win)">
                                    <span class="segment-label">{{ formatPercentage(match.prediction.proba_away_win)
                                        }}</span>
                                </div>
                            </div>
                        </template>
                        <template v-else-if="selectedProbability === 'O_15'">
                            <div class="stacked-progress mb-1">
                                <div class="segment segment-over"
                                    :style="{ width: (match.prediction.proba_over_1_5 * 100) + '%' }"
                                    :title="'Over: ' + formatPercentage(match.prediction.proba_over_1_5)">
                                    <span class="segment-label">{{ formatPercentage(match.prediction.proba_over_1_5)
                                        }}</span>
                                </div>
                                <div class="segment segment-under"
                                    :style="{ width: (match.prediction.proba_under_1_5 * 100) + '%' }"
                                    :title="'Under: ' + formatPercentage(match.prediction.proba_under_1_5)">
                                    <span class="segment-label">{{ formatPercentage(match.prediction.proba_under_1_5)
                                        }}</span>
                                </div>
                            </div>
                        </template>
                        <template v-else-if="selectedProbability === 'O_25'">
                            <div class="stacked-progress mb-1">
                                <div class="segment segment-over"
                                    :style="{ width: (match.prediction.proba_over_2_5 * 100) + '%' }"
                                    :title="'Over: ' + formatPercentage(match.prediction.proba_over_2_5)">
                                    <span class="segment-label">{{ formatPercentage(match.prediction.proba_over_2_5)
                                        }}</span>
                                </div>
                                <div class="segment segment-under"
                                    :style="{ width: (match.prediction.proba_under_2_5 * 100) + '%' }"
                                    :title="'Under: ' + formatPercentage(match.prediction.proba_under_2_5)">
                                    <span class="segment-label">{{ formatPercentage(match.prediction.proba_under_2_5)
                                        }}</span>
                                </div>
                            </div>
                        </template>
                        <template v-else-if="selectedProbability === 'BTTS'">
                            <div class="stacked-progress mb-1">
                                <div class="segment segment-btts-yes"
                                    :style="{ width: (match.prediction.proba_btts_yes * 100) + '%' }"
                                    :title="'Yes: ' + formatPercentage(match.prediction.proba_btts_yes)">
                                    <span class="segment-label">{{ formatPercentage(match.prediction.proba_btts_yes)
                                        }}</span>
                                </div>
                                <div class="segment segment-btts-no"
                                    :style="{ width: (match.prediction.proba_btts_no * 100) + '%' }"
                                    :title="'No: ' + formatPercentage(match.prediction.proba_btts_no)">
                                    <span class="segment-label">{{ formatPercentage(match.prediction.proba_btts_no)
                                        }}</span>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div v-else>
                        Keine Vorhersage
                    </div>
                </v-card-text>
            </v-card>
        </div>

        <!-- Hinweis, wenn keine Matches vorhanden sind -->
        <div v-else class="text-center my-4 d-block d-md-none">
            <v-alert type="info" color="info">
                Für diesen Tag sind keine Spiele verfügbar.
            </v-alert>
        </div>
    </v-container>
</template>

<script>
import moment from 'moment'
import axios from 'axios'

export default {
    name: 'MatchesTable',
    data() {
        return {
            selectedDate: new Date(),
            selectedProbability: "1X2",
            matches: [],
            error: null,
            loading: false
        }
    },
    computed: {
        formattedDate() {
            return moment(this.selectedDate).format('DD.MM.YYYY')
        }
    },
    methods: {
        fetchMatches() {
            this.loading = true;
            const formattedDate = moment(this.selectedDate).format('YYYY-MM-DD')
            axios.get(`${process.env.VUE_APP_API_URL}/matches/?date=${formattedDate}`)
                .then(response => {
                    this.matches = response.data;
                    this.loading = false;
                })
                .catch(error => {
                    this.error = "Fehler beim Laden der Matches.";
                    console.error("Fehler beim Laden der Matches:", error);
                    this.loading = false;
                })
        },
        prevDay() {
            this.selectedDate = new Date(this.selectedDate.getTime() - 86400000)
            this.fetchMatches()
        },
        nextDay() {
            this.selectedDate = new Date(this.selectedDate.getTime() + 86400000)
            this.fetchMatches()
        },
        goToMatch(id) {
            this.$router.push(`/matches/${id}`)
        },
        formatPercentage(value) {
            return value != null ? (value * 100).toFixed(1) + '%' : '-'
        },
        formatOdds(value) {
            return value != null ? Number(value).toFixed(2) : '-';
        }
    },
    mounted() {
        this.fetchMatches()
    }
}
</script>

<style scoped>
/* Hier kannst du weitere Styles zur Optimierung hinzufügen */
.stacked-progress {
    display: flex;
    width: 100%;
    height: 20px;
    border-radius: 4px;
    overflow: hidden;
    background-color: #e0e0e0;
}

.segment {
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.segment-label {
    font-size: 10px;
    color: white;
    font-weight: bold;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

/* 1X2 */
.segment-home {
    background-color: #4caf50;
    /* Success (Grün) */
}

.segment-draw {
    background-color: #ff9800;
    /* Warning/Orange */
}

.segment-away {
    background-color: #f44336;
    /* Error (Rot) */
}

/* Goals */
.segment-over {
    background-color: #4caf50;
}

.segment-under {
    background-color: #f44336;
}

/* BTTS */
.segment-btts-yes {
    background-color: #4caf50;
}

.segment-btts-no {
    background-color: #f44336;
}
</style>