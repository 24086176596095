<template>
    <v-app>
        <!-- Sidebar -->
        <v-navigation-drawer v-model="drawer" app dark>
            <v-list-item class="pa-4">
                <!-- Logo (bitte den Pfad anpassen) -->
                <v-img src="@/assets/logo.png" max-height="64" contain></v-img>
            </v-list-item>
            <!-- Hier kannst du später weitere Navigations-Elemente hinzufügen -->
        </v-navigation-drawer>

        <!-- Top App Bar -->
        <v-app-bar app dark>
            <!-- Burger Menü zum Toggle der Sidebar -->
            <v-app-bar-nav-icon @click="toggleDrawer" />
            <!-- Link als Navigationseintrag -->
            <router-link to="/" class="top-nav-link">
                Dashboard
            </router-link>

            <router-link to="/betbuddy" class="top-nav-link">BetBuddy</router-link>

            <router-link to="/league-stats" class="top-nav-link">LeagueStats</router-link>
        </v-app-bar>

        <!-- Content-Bereich -->
        <v-main>
            <v-container fluid>
                <router-view />
            </v-container>
        </v-main>

        <!-- Footer -->
        <v-footer app dark>
            <v-container>
                <v-row class="d-flex align-center" justify="space-between">
                    <v-col cols="12" md="6">
                        <span>&copy; 2025 Soccer-Ratings. Alle Rechte vorbehalten.</span>
                    </v-col>
                    <v-col cols="12" md="6" class="text-right">
                        <router-link to="/impressum" class="footer-link">
                            Impressum
                        </router-link>
                    </v-col>
                </v-row>
            </v-container>
        </v-footer>
    </v-app>
</template>

<script>
export default {
    name: 'MainLayout',
    data() {
        return {
            drawer: false, // Sidebar initial geöffnet
        }
    },
    methods: {
        toggleDrawer() {
            this.drawer = !this.drawer;
        },
    },
};
</script>

<style scoped>
/* Eigene Styles für das Layout */
/* Top App Bar Link */
.top-nav-link {
    color: white;
    font-size: 16px;
    text-decoration: none;
    margin-left: 16px;
    line-height: 64px;
    /* Höhe der App Bar ausgleichen */
    transition: color 0.2s, text-decoration 0.2s;
}

.top-nav-link:hover {
    text-decoration: underline;
}

/* Footer Link */
.footer-link {
    color: white;
    font-size: 14px;
    text-decoration: none;
    transition: text-decoration 0.2s;
}

.footer-link:hover {
    text-decoration: underline;
}
</style>